@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: Helvetica, sans-serif;
}
:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 61, 61, 61;
  --background-end-rgb: 255, 255, 255;
}
/* 
@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
} */

body {
  color: rgb(var(--foreground-rgb));
  background: rgb(var(--background-start-rgb));
}
.user-list table {
  background-color: rgb(209 209 209);
}
.blue-links a {
  color: #2ba5ce;
  background-color: #1b9bd11a;
  border-radius: 3px;
  padding: 0 4px 1px;
}
/* Apply the base styling for the table */
.message-container table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #b0b0b0;
  border-radius: 6px;
  background-color: #f8f7f7;
}
.message-container table {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Style for table headers */
.message-container th {
  color: #292525;
  font-size: 16px;
  font-weight: 550;
  line-height: 22.4px;
  font-style: normal;
  padding: 12px;
  text-align: left;
  max-width: 32cqw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.message-container tr {
  border: 1px solid #b0b0b0;
}
/* Style for table cells */
.message-container td,
.message-container th {
  padding: 1em;
}

/* List Styles */
ul,
ol {
  list-style: disc;
  padding: 5px;
  margin-left: 2em;
}
ol {
  list-style: decimal;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.card-subtitle {
  font-size: 0.875rem; /* Slightly smaller than main text */
  font-weight: 400;
  color: #6b7280; /* A muted gray color */
  margin-top: 0.25rem; /* Add a small top margin for spacing */
}

.component-title {
  width: 100%;
  position: absolute;
  z-index: 999;
  top: 30px;
  left: 0;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
  color: #888;
  text-align: center;
}

/* The switch - the box around the slider */
.container {
  width: 51px;
  height: 31px;
  position: relative;
}

.toggle-container {
  width: 51px;
  height: 31px;
  position: relative;
}
/* Hide default HTML checkbox */
.checkbox {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.switch {
  width: 100%;
  height: 100%;
  display: block;
  background-color: #e9e9eb;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}
.column-switch {
  width: 100%;
  height: 100%;
  display: block;
  background-color: #e9e9eb;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}
.small-slider {
  width: 13px;
  height: 13px;
  position: absolute;
  left: calc(50% - 5px); /* Center the slider horizontally */
  top: 2px; /* Position the slider at the top */
  border-radius: 50%;
  background: #ffffff;
  box-shadow:
    0px 3px 8px rgba(0, 0, 0, 0.15),
    0px 3px 1px rgba(0, 0, 0, 0.06);
  transition: all 0.2s ease-out;
  cursor: pointer;
}
/* The slider */
.slider {
  width: 27px;
  height: 27px;
  position: absolute;
  left: calc(50% - 27px / 2 - 10px);
  top: calc(50% - 27px / 2);
  border-radius: 50%;
  background: #ffffff;
  box-shadow:
    0px 3px 8px rgba(0, 0, 0, 0.15),
    0px 3px 1px rgba(0, 0, 0, 0.06);
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.checkbox:checked + .switch {
  background-color: #34c759;
}

.checkbox:checked + .switch .slider {
  left: calc(50% - 27px / 2 + 10px);
  top: calc(50% - 27px / 2);
}

.checkbox:checked + .switch .small-slider {
  top: calc(100% - 12px); /* Move the slider to the bottom */
  left: calc(50% - 5px); /* Center the slider horizontally */
}

.resize-handle {
  cursor: ew-resize; /* Change cursor to indicate resizing */
  width: 2px; /* Width of the resize handle */
  background-color: transparent; /* Initially transparent */
  height: 100%; /* Full height of the header */
  position: absolute; /* Position it absolutely */
  right: 0; /* Align it to the right of the header */
  top: 0; /* Align it to the top of the header */
  z-index: 10; /* Ensure it is above other elements */
  transition: background-color 0.2s; /* Smooth transition for hover effect */
}

.flex:hover .resize-handle {
  background-color: rgba(
    0,
    0,
    0,
    0.1
  ); /* Light background for visibility on hover */
}

/* Scrollbar design */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 8px;
  border: 2px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.react-json-view {
  font-family: inherit; /* Use the parent element's font */
  font-size: inherit; /* Adjust font size to match your app */
}

.linear-background-ai-assistance {
  background: linear-gradient(90deg, rgba(112, 92, 246, 0.2) 15.55%, rgba(1, 167, 238, 0.074) 143.26%);
}